<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        For each of the items in the list below, select the appropriate waste container/area.
      </p>

      <v-simple-table>
        <thead>
          <tr v-for="question in questions" :key="question.vModel">
            <td>
              <stemble-latex :content="question.text" />
            </td>
            <td>
              <v-select
                v-model="inputs[question.vModel]"
                :items="options"
                item-text="text"
                item-value="value"
              >
                <template #item="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
                <template #selection="{item}">
                  <stemble-latex class="no-text-transform" :content="item.text" />
                </template>
              </v-select>
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI51LBA6_Q5',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      questions: [
        {
          text: 'a) Used filter paper',
          vModel: 'input1',
        },
        {
          text: 'b) Melting point tube',
          vModel: 'input2',
        },
        {
          text: 'c) Acetone rinse',
          vModel: 'input3',
        },
        {
          text: 'd) Reaction filtrate',
          vModel: 'input4',
        },
        {
          text: 'e) Leftover water from ice bath',
          vModel: 'input5',
        },
        {
          text: 'f) Broken waterless condenser',
          vModel: 'input6',
        },
        {
          text: 'g) Leftover starting material',
          vModel: 'input7',
        },
      ],
      options: [
        {text: 'Solid Waste Container', value: 'solid'},
        {text: 'Liquid Waste Container', value: 'liquid'},
        {text: 'Glass Waste Container', value: 'glass'},
        {text: 'Trash', value: 'trash'},
        {text: 'Sink', value: 'sink'},
      ],
    };
  },
};
</script>
